import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useInView } from "react-intersection-observer";
import { Text } from "@otrium/atoms";
import { Trans } from "@lingui/macro";
import { BrandLabel, CardDisplayType } from "src/types/graphql.d";
import {
  SEGMENT_IN_VIEW_THRESHOLD,
  SEGMENT_POSITIONS,
  SEGMENT_PROMOTION_TYPE,
} from "src/segment";
import { PromotionEventType, useSegment } from "src/hooks/useSegment";
import { Timer } from "src/atoms/Timer";
import {
  CardContent,
  CardImage,
  CardImageContainer,
  CardImageWrapper,
  CardWrapper,
  StyledHeading,
  StyledLink,
  StyledPromoLabel,
  StyledPromoLabelsContainer,
  StyledPromoLabelsWrapper,
  StyledCTAButton,
} from "./Card.styled";
import { sanitizeString } from "src/organisms/ProductOrder/utils";
import Link from "next/link";
import NoSSR from "src/atoms/NoSSR";
import { theme } from "src/theme";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";
import { useFeatureFlags } from "src/hooks/useFeatureFlags";

export interface CardProps {
  image: {
    src: string | undefined;
    alt: string | undefined;
  };
  flexDirection?: "row" | "row-reverse" | "column";
  title: string | undefined | JSX.Element;
  titleForTracking?: string;
  subTitle: string | undefined | JSX.Element;
  link: string | undefined;
  linkCTA?: string;
  ctaStyle?: "link" | "button";
  promoLabels?: BrandLabel[] | undefined;
  getImageElementWrapper?: Dispatch<SetStateAction<HTMLDivElement | null>>;
  position?: SEGMENT_POSITIONS;
  withHorizontalImage?: boolean;
  promotionType?: SEGMENT_PROMOTION_TYPE;
  linkAsText?: boolean;
  timerEndDate?: string;
  cardDisplayType?: CardDisplayType;
  isGated?: boolean;
  openGatedPopup?: () => void;
}

const Card: FC<CardProps> = ({
  image,
  title,
  titleForTracking,
  subTitle,
  link,
  linkCTA,
  ctaStyle,
  linkAsText = false,
  promoLabels,
  flexDirection = "row",
  getImageElementWrapper,
  position,
  promotionType,
  withHorizontalImage = false,
  timerEndDate,
  cardDisplayType = "DEFAULT",
  isGated,
  openGatedPopup,
}) => {
  const isFlashSale = cardDisplayType === CardDisplayType.FlashSale;
  const [hasFlashSaleEnd, setFlashSaleEnd] = useState(false);
  const isLoggedIn = useIsLoggedIn();
  const { enableGatedHomepage } = useFeatureFlags();
  const promolabels = promoLabels?.length
    ? promoLabels.map((promoLabel, index: number) => (
        <StyledPromoLabel
          key={index}
          textColor={promoLabel.text_color || undefined}
          labelColor={promoLabel.color}
        >
          {sanitizeString(promoLabel.value)}
        </StyledPromoLabel>
      ))
    : null;

  const { segmentPromotionClickedOrViewed } = useSegment();

  const [ref, inView] = useInView({
    threshold: SEGMENT_IN_VIEW_THRESHOLD,
    triggerOnce: true,
  });

  const trackingData = useMemo(
    () => ({
      creative: image.src,
      name: titleForTracking,
      position,
      promotionType,
      gated: !!(!isLoggedIn && enableGatedHomepage),
    }),
    [
      image.src,
      titleForTracking,
      position,
      promotionType,
      isLoggedIn,
      enableGatedHomepage,
    ]
  );

  useEffect(() => {
    if (inView && promotionType) {
      void segmentPromotionClickedOrViewed({
        ...trackingData,
        eventType: PromotionEventType.view,
      });
    }
  }, [inView, promotionType, segmentPromotionClickedOrViewed, trackingData]);

  const onClickHandler = () => {
    if (promotionType) {
      void segmentPromotionClickedOrViewed({
        ...trackingData,
        eventType: PromotionEventType.click,
      });
    }
  };

  const onTimerEnd = useCallback(
    (hasTimerEnded: boolean) => setFlashSaleEnd(hasTimerEnded),
    [setFlashSaleEnd]
  );

  return (
    <CardWrapper flexDirection={flexDirection} ref={ref}>
      <CardImageContainer
        ref={getImageElementWrapper}
        flexDirection={flexDirection}
      >
        <CardImageWrapper
          withHorizontalImage={withHorizontalImage}
          hasTimerEnded={hasFlashSaleEnd}
        >
          <CardImage
            src={image.src || ""}
            alt={image.alt || ""}
            fill
            sizes="100%, 100%"
          />
          {promolabels ? (
            <StyledPromoLabelsContainer>
              <StyledPromoLabelsWrapper>{promolabels}</StyledPromoLabelsWrapper>
            </StyledPromoLabelsContainer>
          ) : null}
        </CardImageWrapper>
      </CardImageContainer>
      <CardContent flexDirection={flexDirection}>
        <StyledHeading as="h3">{title}</StyledHeading>
        {!hasFlashSaleEnd ? (
          <Text
            lineHeight={1.5}
            color="tone.black"
            marginBottom={2}
            as="p"
            textAlign={["left", "left", "center"]}
          >
            {subTitle}
          </Text>
        ) : null}
        {isFlashSale ? (
          <Text
            lineHeight={1.5}
            color="tone.black"
            fontWeight={hasFlashSaleEnd ? 300 : 600}
            marginBottom={2}
            as={"span"}
            textAlign={["left", "left", "center"]}
          >
            {timerEndDate && !hasFlashSaleEnd ? (
              <NoSSR>
                <Timer
                  endDate={timerEndDate || ""}
                  showLabels={false}
                  customFontSizes={{ divider: "fontSize14" }}
                  customSpacings={{
                    numbersGap: "space0",
                    containerMarginBottom: "space0",
                  }}
                  onTimerEnd={onTimerEnd}
                />
              </NoSSR>
            ) : (
              <Trans>Flash sale has ended</Trans>
            )}
          </Text>
        ) : null}

        {isGated ? (
          <StyledCTAButton as="div" onClick={openGatedPopup}>
            {linkCTA || <Trans>Shop collection</Trans>}
          </StyledCTAButton>
        ) : isFlashSale && hasFlashSaleEnd ? null : ctaStyle === "button" ? (
          <StyledCTAButton as="a" href={link}>
            {linkCTA || <Trans>Shop collection</Trans>}
          </StyledCTAButton>
        ) : (
          <StyledLink
            href={link}
            onClick={onClickHandler}
            as={linkAsText ? "span" : Link}
            color={isFlashSale ? theme.colors.semantic.red : undefined}
          >
            {linkCTA || <Trans>Shop collection</Trans>}
          </StyledLink>
        )}
      </CardContent>
    </CardWrapper>
  );
};

export default Card;
